import React from "react";
import logoLight from "../Assets/logo_light.png";
import { useNavigate } from "react-router-dom";
import {
  FaPhone,
  FaInstagramSquare,
  FaFacebook,
  FaTwitter,
  FaEnvelopeSquare,
  FaMapMarker,
  FaWhatsapp,
} from "react-icons/fa";

export let details = [
  { icon: <FaPhone />, text: "+2348085546073", type: "tel" },
  { icon: <FaEnvelopeSquare />, text: "support@visco.com.ng", type: "mail" },
  {
    icon: <FaMapMarker />,
    text: "Polystar 2nd Roundabout, Lekki Phase 1 105102, Lagos",
    type: "address",
  },
];
const Footer = () => {
  const navigate = useNavigate();
  let quick = [
    { name: "Home", url: "/" },
    { name: "About Us", url: "/about" },
    { name: "Contact Us", url: "/contact" },
    { name: "Privacy Policy", url: "/privacy-policy" },
    { name: "Terms and Conditions", url: "/terms-and-conditions" },
  ];

  let resources = [
    { name: "FAQs", url: "/faqs" },
    { name: "Become an agent", url: "/become-an-agent" },
  ];

  let socials = [
    {
      icon: <FaWhatsapp size={20} color="white" />,
      url: "https://wa.me/message/QNHCJYN6TSZGG1",
    },
    {
      icon: <FaInstagramSquare size={20} color="white" />,
      url: "https://instagram.com/viscio_express?igshid=YmMyMTA2M2Y=",
    },
    {
      icon: <FaFacebook size={20} color="white" />,
      url: "https://www.facebook.com/profile.php?id=100077522783530",
    },
    {
      icon: <FaTwitter size={20} color="white" />,
      url: "https://twitter.com/ViscioExpress?t=WwN0_bwU-xC_1Qr0PlIc9A&s=09",
    },
  ];

  return (
    <footer className="tw-bg-[#151515] tw-py-16 fix">
      <div className="container">
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-7 lg:tw-space-y-0 tw-justify-between">
          <div className="tw-max-w-md">
            <img src={logoLight} alt="VISCIO" className="logo logo2 tw-mb-4" />
            <p className="tw-text-base tw-max-w-sm tw-font-normal tw-text-[#c0c0c0]">
              Empowering Farmers and Agro-Logistics Providers through Seamless
              Technology-driven Collaboration
            </p>
            <div className="tw-flex tw-gap-8 tw-items-center">
              {socials?.map((soc, index) => (
                <div key={index}>
                  <a href={soc?.url}>{soc?.icon}</a>
                </div>
              ))}
            </div>
            <form
              action=""
              onSubmit={(e) => e?.preventDefault()}
              className="tw-mt-8 tw-max-w-lg"
            >
              <p className="tw-text-base tw-font-normal tw-text-[#EBEBEB] poppins">
                Subscribe to get latest updates on our offers
              </p>
              <div className="tw-relative tw-h-14 tw-max-w-lg">
                <input
                  type="text"
                  style={{
                    border: "1px solid #001B8799",
                  }}
                  placeholder="Enter your email address"
                  className="tw-rounded-full tw-pl-6 tw-h-full tw-max-w-full tw-text-xs tw-text-[#111114] poppins placeholder:tw-font-light tw-font-normal tw-w-full tw-bg-white"
                />
                <button className="tw-h-10 tw-absolute tw-top-2 tw-right-3 tw-w-44 tw-bg-[#001b87] tw-text-sm tw-text-white tw-rounded-full poppins">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-16">
            <div>
              <h5 className="tw-text-2xl tw-font-semibold tw-text-[#EBEBEB] poppins">
                Quick Links
              </h5>
              <div className="tw-mt-6 tw-list-none tw-space-y-4 tw-text-[#C0C0C0] tw-text-base tw-font-normal mont">
                {quick?.map((q) => (
                  <p
                    className="tw-cursor-pointer"
                    onClick={() => navigate(q?.url)}
                  >
                    {q?.name}
                  </p>
                ))}
              </div>
            </div>
            <div>
              <h5 className="tw-text-2xl tw-font-semibold tw-text-[#EBEBEB] poppins">
                Resources
              </h5>
              <div className="tw-mt-6 tw-list-none tw-space-y-4 tw-text-[#C0C0C0] tw-text-base tw-font-normal mont">
                {resources?.map((q) => (
                  <p
                    className="tw-cursor-pointer"
                    onClick={() => navigate(q?.url)}
                  >
                    {q?.name}
                  </p>
                ))}
              </div>
            </div>
            <div>
              <h5 className="tw-text-2xl tw-font-semibold tw-text-[#EBEBEB] poppins">
                Get in Touch
              </h5>
              <div className="tw-mt-6 tw-list-none tw-space-y-4 tw-text-[#C0C0C0] tw-text-base tw-font-normal mont">
                {details?.map((q) => (
                  <div className="tw-flex tw-gap-2 tw-items-center">
                    <div>{q?.icon}</div>
                    <small className="tw-cursor-pointer tw-w-56">
                      {q?.text}
                    </small>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
