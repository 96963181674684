import React from "react";
import { NewHeaderMain } from "../Components/Header";
import Partner1 from "../Assets/august.svg";
import Partner2 from "../Assets/WhatsApp Image 2024-08-10 at 19.50.48 1.svg";
import Partner3 from "../Assets/abc.svg";
import Partner4 from "../Assets/fez.svg";
import Partner5 from "../Assets/chisco.svg";
import Partner6 from "../Assets/abc2.svg";
import ReliableIcon from "../Assets/reliablilityicon2.svg";
import NetworkIcon from "../Assets/chart.svg";
import ReachIcon from "../Assets/programming-arrows.svg";
import ShieldIcon from "../Assets/shield.svg";
import { FaArrowRight } from "react-icons/fa";
import Lagos from "../Assets/lagos.png";
import Avatar from "../Assets/avatar.svg";
import Kaduna from "../Assets/kaduna.png";
import Appstore from "../Assets/appstore.svg";
import PlayStore from "../Assets/playstore.svg";
import Phone from "../Assets/phone.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const NewHome = () => {
  const partners = [Partner1, Partner2, Partner3, Partner4, Partner5, Partner6];
  return (
    <div className="tw-w-full">
      <div className="tw-w-full newHeroBg lg:tw-h-[120vh] tw-h-screen tw-relative">
        <div className="container tw-h-full tw-flex tw-items-center tw-justify-center">
          <div className="lg:tw-w-3/4 tw-w-full lg:tw-mt-10 tw-mx-auto">
            <h1 className="lg:tw-text-6xl tw-text-[40px] lg:tw-leading-[1.2] tw-text-white tw-font-semibold poppins tw-text-center">
              We make{" "}
              <span className="gradient-text2 lg:tw-text-6xl tw-text-4xl tw-font-semibold">
                Farm2Market
              </span>
               Agro-Logistics Efficient, Predictable and Affordable
            </h1>
            <p className="lg:tw-text-2xl tw-text-xl tw-mt-5 tw-font-normal tw-text-white poppins tw-text-center tw-max-w-3xl tw-mx-auto">
              Empowering Farmers and Agro-Logistics Providers through Seamless
              Technology-driven Collaboration
            </p>
            <form
              action=""
              onSubmit={(e) => e?.preventDefault()}
              className="tw-mt-8"
            >
              <div className="tw-relative tw-h-20 lg:tw-w-1/2 tw-mx-auto">
                <input
                  type="text"
                  style={{
                    border: "1px solid #001B8799",
                  }}
                  placeholder="Input tracking ID"
                  className="tw-rounded-full tw-pl-6 tw-h-full tw-text-xl tw-text-[#111114] poppins placeholder:tw-font-light tw-font-normal tw-w-full tw-bg-white"
                />
                <button className="tw-h-14 tw-absolute tw-top-3 tw-right-5 tw-w-44 tw-bg-[#001b87] tw-text-xl tw-text-white tw-rounded-full poppins">
                  Track
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0">
          <NewHeaderMain light={true} />
        </div>
      </div>
      <div className="tw-w-full tw-bg-white lg:tw-h-36 tw-py-16 lg:tw-py-0">
        <div className="container tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-h-full">
          <div>
            <h2 className="tw-text-2xl tw-font-medium inter tw-text-primary">
              Our Partners
            </h2>
          </div>
          <div className="tw-flex tw-flex-wrap lg:tw-flex-row tw-gap-6 tw-items-center">
            {partners?.map((p) => (
              <img src={p} alt="" className="tw-h-14" />
            ))}
          </div>
        </div>
      </div>
      <WhySection />
      <CoverageSection />
      <DrivingSection />
      <TestimonySection />
      <DownloadSection />
    </div>
  );
};

const WhySection = () => {
  const Arr = [
    {
      title: "Unmatched reliability",
      bg: "#65AEE81A",
      desc: "With 1780+ successful trips, we ensure your agricultural products reach their destination safely and on time.",
      icon: ReliableIcon,
      color: "#242424",
    },
    {
      title: "Extensive network",
      bg: "#008080",
      desc: "Serving 400+ satisfied customers and working with 200+ active vendors, we connect you with a vast and trusted supply chain.",
      icon: NetworkIcon,
    },
    {
      title: "nationwide reach",
      bg: "#073042",
      desc: "Operating from 15+ strategic bases, we cover key agricultural regions, ensuring broad and efficient service.",
      icon: ReachIcon,
    },
    {
      title: "24/7 Availability",
      bg: "#0080801A",
      desc: "We’re always on, providing round-the-clock logistics support to keep your business moving forward without delays.",
      icon: ShieldIcon,
      color: "#242424",
    },
  ];
  return (
    <section className="tw-w-full tw-py-24 tw-bg-[#F3FFF8]">
      <div className="container">
        <h2 className="lg:tw-text-5xl tw-text-2xl tw-text-primary tw-font-semibold mont">
          Why Choose Us
        </h2>
        <div className="tw-mt-8 tw-grid lg:tw-grid-cols-2 tw-gap-14">
          {Arr?.map((it) => (
            <div
              style={{
                background: it?.bg,
                color: it?.color || "white",
              }}
              className="tw-rounded-3xl tw-p-8 tw-h-[500px] tw-relative"
            >
              <h3 className="lg:tw-text-3xl tw-text-xl tw-capitalize tw-font-semibold mont tw-max-w-sm">
                {it?.title}
              </h3>
              <p className="tw-mt-5 lg:tw-text-xl tw-text-base tw-font-normal inter tw-w-3/4">
                {it?.desc}
              </p>
              <div className="tw-absolute tw-bottom-5 tw-right-6">
                <img src={it?.icon} alt="" className="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CoverageSection = () => {
  const Arr = [
    {
      img: Lagos,
      name: "lagos",
      desc: "Lagos is the epicenter of our operations, ensuring swift and reliable agricultural logistics for bustling markets and businesses.",
    },
    {
      img: Kaduna,
      name: "kaduna",
      desc: "Kaduna connects rural produce to urban demand, streamlining agricultural supply chains.",
    },
    {
      img: Lagos,
      name: "oyo",
      desc: "Rich in agricultural heritage, Oyo is a key hub for our logistics, bridging local farms with wider markets across the country.",
    },
    {
      img: Lagos,
      name: "lagos",
      desc: "Lagos is the epicenter of our operations, ensuring swift and reliable agricultural logistics for bustling markets and businesses.",
    },
    {
      img: Kaduna,
      name: "kaduna",
      desc: "Kaduna connects rural produce to urban demand, streamlining agricultural supply chains.",
    },
    {
      img: Lagos,
      name: "oyo",
      desc: "Rich in agricultural heritage, Oyo is a key hub for our logistics, bridging local farms with wider markets across the country.",
    },
  ];
  return (
    <section className="tw-w-full tw-py-20">
      <div className="container">
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-center">
          <div>
            <div className="tw-h-8 tw-w-44 tw-flex tw-rounded-md tw-justify-center tw-items-center tw-bg-[#FFF2F4]">
              <span className="tw-text-sm poppins tw-font-semibold tw-text-[#F4334A]">
                We got you covered
              </span>
            </div>
            <h2 className="tw-mt-6 lg:tw-text-5xl tw-text-3xl tw-font-semibold tw-text-primary mont">
              Our Coverage
              <br /> Cities
            </h2>
          </div>
          <div className="tw-mt-10 lg:tw-mt-0">
            <p className="lg:tw-text-2xl tw-text-base tw-text-[#282828] tw-max-w-xl tw-font-light poppins">
              Our commitment to serve you constantly propels us to enlarge our
              coverage locations to ensure efficient service delivery and
              proximity to our priority channels.
            </p>
            <div
              style={{
                border: "1px solid #001B87",
              }}
              className="tw-mt-6 tw-h-14 tw-cursor-pointer tw-w-52 tw-flex tw-justify-center tw-gap-5 tw-items-center tw-rounded-full"
            >
              <span className="tw-text-2xl tw-font-normal inter tw-text-[#001B87]">
                Learn more
              </span>
              <FaArrowRight color="#001B87" />
            </div>
          </div>
        </div>

        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className="mySwiper tw-mt-12 tw-w-full tw-h-full tw-pb-32"
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination, Autoplay, Mousewheel]}
        >
          {Arr?.map((it) => (
            <SwiperSlide className="tw-mb-20">
              <div>
                <img src={it?.img} alt="" className="tw-h-80 tw-w-full" />
                <div className="tw-mt-6">
                  <h5 className="lg:tw-text-2x tw-text-basel tw-font-semibold tw-text-primary mont tw-capitalize">
                    {it?.name}
                  </h5>
                  <p className=" lg:tw-text-xl tw-text-sm tw-font-normal tw-text-[#282828] inter">
                    {it?.desc}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

const DrivingSection = () => {
  const sections = [
    { name: "customers", value: "650+" },
    { name: "trips", value: "1780+" },
    { name: "active vendors", value: "300+" },
    { name: "operations base", value: "17+" },
    { name: "availability", value: "24/7" },
  ];
  return (
    <section className="tw-bg-[#040711] tw-w-full tw-py-20">
      <div className="container">
        <h2 className="lg:tw-text-6xl tw-text-4xl tw-text-center tw-leading-[1.2] tw-font-semibold poppins tw-text-white">
          Driving{" "}
          <span className="gradient-text2 lg:tw-text-6xl tw-text-4xl tw-font-semibold poppins">
            Agriculture
          </span>
          <br />
          Forward
        </h2>
        <p className="lg:tw-text-2xl tw-text-base tw-font-normal tw-mt-6 poppins tw-text-center tw-w-5/6 tw-mx-auto tw-text-white">
          Over 1,300 Successful Trips and Counting, Serving 400+ Customers with
          24/7 Availability Across 15+ Locations and Partnering with 200+ Active
          Vendors
        </p>
        <div className="tw-mt-12 tw-w-full tw-bg-[#FFFFFF33] lg:tw-h-40 tw-rounded-2xl tw-px-8 tw-py-8 lg:tw-py-0 tw-flex tw-flex-col tw-space-y-5 lg:tw-space-y-0 lg:tw-flex-row tw-items-center tw-justify-between">
          {sections?.map((section) => (
            <div
              style={{
                boxShadow: "0px 2px 2px 0px #00000026",
                border: "0.5px solid #FFFFFFCC",
              }}
              className="tw-h-32 tw-rounded-2xl tw-flex tw-items-center tw-justify-center tw-px-8"
            >
              <div>
                <h5 className="tw-text-xl tw-capitalize tw-text-center tw-font-normal tw-text-[#FFFFFFCC] poppins">
                  {section?.name}
                </h5>
                <h2 className="tw-text-2xl tw-text-center tw-font-semibold poppins gradient-text">
                  {section?.value}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const TestimonySection = () => {
  const testimonies = [
    {
      Avatar,
      name: "ajani ben",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum tristique id lorem integer massa. Et pretium non turpis id volutpat fringilla dictumst nunc. Laoreet egestas enim quam venenatis tortor proin.",
    },
    {
      Avatar,
      name: "ajani ben",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum tristique id lorem integer massa. Et pretium non turpis id volutpat fringilla dictumst nunc. Laoreet egestas enim quam venenatis tortor proin.",
    },
    {
      Avatar,
      name: "ajani ben",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum tristique id lorem integer massa. Et pretium non turpis id volutpat fringilla dictumst nunc. Laoreet egestas enim quam venenatis tortor proin.",
    },
    {
      Avatar,
      name: "ajani ben",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum tristique id lorem integer massa. Et pretium non turpis id volutpat fringilla dictumst nunc. Laoreet egestas enim quam venenatis tortor proin.",
    },
    {
      Avatar,
      name: "ajani ben",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum tristique id lorem integer massa. Et pretium non turpis id volutpat fringilla dictumst nunc. Laoreet egestas enim quam venenatis tortor proin.",
    },
    {
      Avatar,
      name: "ajani ben",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum tristique id lorem integer massa. Et pretium non turpis id volutpat fringilla dictumst nunc. Laoreet egestas enim quam venenatis tortor proin.",
    },
    {
      Avatar,
      name: "ajani ben",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum tristique id lorem integer massa. Et pretium non turpis id volutpat fringilla dictumst nunc. Laoreet egestas enim quam venenatis tortor proin.",
    },
  ];
  return (
    <section className="tw-w-full tw-bg-[#F5F7FF] tw-py-24">
      <div className="container">
        <div className="tw-h-8 tw-w-44 tw-flex tw-rounded-md tw-justify-center tw-items-center tw-bg-[#FFF2F4]">
          <span className="tw-text-sm poppins tw-font-semibold tw-text-[#F4334A]">
            Testimonials
          </span>
        </div>
        <div className="tw-mt-8">
          <h2 className="lg:tw-text-5xl tw-text-3xl tw-font-medium poppins tw-text-[#333333]">
            What our customers say
          </h2>
          <p className="tw-mt-6 tw-max-w-2xl lg:tw-text-xl tw-text-base tw-font-normal tw-text-[#292929]">
            Take a dive into the reviews of what our user have to say about
            their experience at with Viscio Express
          </p>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className="mySwiper tw-mt-12 tw-w-full tw-h-full tw-pb-32"
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            modules={[Pagination, Autoplay, Mousewheel]}
          >
            {testimonies.map((item, index) => (
              <SwiperSlide>
                <div
                  style={{
                    boxShadow: "0px 4px 40px 0px #2626261A",
                  }}
                  data-aos="zoom-in"
                  className="tw-rounded-2xl tw-mb-20 tw-mx-auto lg:tw-mx-0 bg-white tw-p-8 tw-w-96"
                  key={index}
                >
                  <div className="tw-relative tw-flex tw-flex-col lg:tw-flex-row tw-justify-center">
                    <img
                      data-aos="zoom-in"
                      src={item.Avatar}
                      alt={`Testifier ${item.name}`}
                      className="img-fluid tw-mx-auto imgTestimony"
                    />
                  </div>
                  <p
                    className="tw-text-lg tw-my-4 tw-font-light poppins tw-text-[#292929] tw-text-center"
                    data-aos="zoom-in"
                  >
                    {item.text}
                  </p>
                  <div className="my-2 border-top tw-w-1/3 mx-auto borderColor"></div>
                  <h6
                    data-aos="zoom-in"
                    className=" tw-capitalize tw-text-center"
                  >
                    {item.name}
                  </h6>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export const DownloadSection = () => {
  return (
    <section className="tw-w-full tw-py-40 tw-bg-white">
      <div className="container tw-relative">
        <div
          style={{
            boxShadow:
              "0px 0px 0px 0px #0000001A, " +
              "-9px 7px 25px 0px #0000001A, " +
              "-35px 28px 45px 0px #00000017, " +
              "-80px 64px 61px 0px #0000000D, " +
              "-141px 114px 73px 0px #00000003, " +
              "-221px 178px 79px 0px #00000000",
          }}
          className="tw-w-full tw-h-[420px] tw-bg-[#001B87] tw-rounded-[30px] tw-flex tw-items-center tw-px-10 tw-relative"
        >
          <div>
            <h2 className="tw-text-white lg:tw-text-6xl tw-text-4xl tw-font-semibold poppins">
              Download Viscio Express
            </h2>
            <p className="tw-text-white tw-text-xl tw-mt-6 tw-font-light poppins">
              To Send your package and Become a driver
            </p>
            <div className="tw-mt-8 tw-flex tw-gap-6 tw-items-center">
              <img src={Appstore} alt="" className="tw-h-12 lg:tw-h-full" />
              <img src={PlayStore} alt="" className="tw-h-12 lg:tw-h-full" />
            </div>
          </div>
        </div>
        <img
          src={Phone}
          alt=""
          className="tw-absolute tw-hidden lg:tw-block tw-right-20 tw-bottom-[-90px] tw-z-10"
        />
      </div>
    </section>
  );
};

export default NewHome;
