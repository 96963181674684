import React from "react";
import { NewHeaderMain } from "../Components/Header";
import MainImg from "../Assets/ctaimg.png";
import Partner1 from "../Assets/august.svg";
import Partner2 from "../Assets/WhatsApp Image 2024-08-10 at 19.50.48 1.svg";
import Partner3 from "../Assets/abc.svg";
import Partner4 from "../Assets/fez.svg";
import Partner5 from "../Assets/chisco.svg";
import Partner6 from "../Assets/abc2.svg";
import CupIcon from "../Assets/cup.svg";
import Programming from "../Assets/programming-arro.svg";
import Layer from "../Assets/layer.svg";
import Contents from "../Assets/Contents.png";
import Chart from "../Assets/new-chart.svg";
import Pic1 from "../Assets/pic1.png";
import Pic2 from "../Assets/pic2.png";
import Pic3 from "../Assets/pic3.png";
import Adeoye from "../Assets/adeoye.png";
import Enene from "../Assets/enene.png";
import Akani from "../Assets/akani.png";
import John from "../Assets/john.png";
import { DownloadSection } from "./new-home";
import CountUp from "react-countup";

const About = () => {
  return (
    <div>
      <section className="tw-w-full">
        <NewHeaderMain light={false} color={"#333333"} />
        <div className="container lg:tw-mt-20 tw-mt-16">
          <div className="tw-flex tw-justify-center">
            <div className="lg:tw-w-2/3 ">
              <div className="tw-h-8 tw-w-36 tw-rounded-full tw-mx-auto tw-flex tw-justify-center tw-items-center tw-bg-[#E6E8F3]">
                <span className="tw-text-sm poppins tw-font-semibold tw-text-[#001B87]">
                  About Us
                </span>
              </div>
              <div className="tw-mt-8">
                <h1 className="tw-text-center tw-mx-auto poppins tw-text-5xl tw-font-medium tw-text-[#333]">
                  Transforming Agro-Logistics Across Africa
                </h1>
                <p className="tw-text-xl tw-font-normal tw-text-center tw-mt-6 poppins tw-text-[#292929]">
                  "Connecting Farmers, Aggregators, and Logistics Agents for
                  Seamless, Sustainable, and Predictable Transport Solutions"
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-mt-16">
          <CtaSection />
          <Partners />
          <MaximizingSection />
          <HereSection />
          <TeamSection />
          <BecomeAgentSection />
          <DownloadSection />
        </div>
      </section>
    </div>
  );
};
const CtaSection = () => {
  const Arr = [
    { title: "customers", value: "650" },
    { title: "operations base", value: "17" },
    { title: "active vendors", value: "300" },
    { title: "trips", value: "1780" },
  ];
  return (
    <section className="tw-w-full tw-py-20 tw-bg-[#E6E8F340]">
      <div className="container">
        <div className="tw-grid lg:tw-grid-cols-2 tw-gap-12 tw-items-center tw-w-full">
          <div>
            <img src={MainImg} alt="" className="tw-rounded-xl tw-w-full" />
          </div>
          <div>
            <h2 className="lg:tw-text-5xl tw-text-4xl tw-font-semibold tw-text-[#333333] poppins">
              Driving Agriculture Forward
            </h2>
            <p className="tw-text-xl tw-font-normal tw-mt-5 tw-text-[#333] poppins">
              Over 1,300 Successful Trips and Counting, Serving 400+ Customers
              with 24/7 Availability Across 15+ Locations and Partnering with
              200+ Active Vendors
            </p>
            <div className="tw-w-2/3 tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-8">
              {Arr?.map((it) => (
                <div className="">
                  <h1 className="lg:tw-text-5xl tw-text-2xl tw-font-semibold poppins gradient-text">
                    <CountUp
                      end={it?.value}
                      duration={6}
                      className="lg:tw-text-5xl tw-text-2xl tw-font-semibold poppins gradient-text"
                    />
                    +
                  </h1>
                  <h6 className="tw-text-[#333] tw-capitalize tw-text-xl tw-font-normal poppins">
                    {it?.title}
                  </h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Partners = () => {
  const partners = [Partner1, Partner2, Partner3, Partner4, Partner5, Partner6];
  return (
    <div className="tw-w-full tw-bg-white lg:tw-h-36 tw-py-16 lg:tw-py-0">
      <div className="container tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-h-full">
        <div>
          <h2 className="tw-text-2xl tw-font-medium inter tw-text-primary">
            Our Partners
          </h2>
        </div>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-6 tw-items-center">
          {partners?.map((p) => (
            <img src={p} alt="" className="tw-h-14" />
          ))}
        </div>
      </div>
    </div>
  );
};
const MaximizingSection = () => {
  const Arr = [
    {
      icon: CupIcon,
      text: "Aggregate and consolidate cargo",
      desc: "In city centers for agro-logistics operators, thereby ensuring timely and profitable back-loading/reverse logistics for onward trips back to farms through traditionally profitable routes.",
    },
    {
      icon: Programming,
      text: "Manage a framework for collaboration",
      desc: "reduce cost of food logistics and unlock productivity in the entire food supply chain.",
    },
    {
      icon: Layer,
      text: "Maximize Mobility asset utilization & cut down carbon emissions",
      desc: "reduce cost of food logistics and unlock productivity in the entire food supply chain.",
    },
    {
      icon: Chart,
      text: "doorstep to the intended recipient in different cities",
      desc: "This affordable pick-up and delivery option is a special scheduled service for movement of goods from doorstep to bus park closest to the intended recipient in another city.",
    },
  ];
  return (
    <section className="tw-w-full tw-py-24">
      <div className="container">
        <h5 className="tw-text-base tw-font-semibold tw-text-[#001B87] mont">
          Our Services
        </h5>
        <h2 className="tw-text-4xl tw-max-w-xl tw-font-semibold tw-text-[#101828] mont">
          Maximizing Efficiency and Sustainability in Food Logistics
        </h2>
        <div className="tw-w-full tw-mt-6 tw-grid lg:tw-grid-cols-2 tw-gap-12">
          <div className="tw-space-y-6 tw-w-full tw-mt-6">
            {Arr?.map((it) => (
              <div className="tw-flex tw-items-start tw-gap-5">
                <img src={it?.icon} alt="" className="" />
                <div className="tw-w-3/4 lg:tw-w-full">
                  <h4 className="tw-text-2xl first-letter:tw-uppercase tw-font-medium poppins tw-text-[#333]">
                    {it?.text}
                  </h4>
                  <p className="tw-text-base tw-font-light poppins tw-text-[#111114]">
                    {it?.desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="tw-flex tw-items-end">
            <img src={Contents} alt="" className="lg:tw-h-[450px] tw-h-96" />
          </div>
        </div>
      </div>
    </section>
  );
};

const HereSection = () => {
  return (
    <section className="tw-w-full tw-py-20 tw-bg-[#F8FDFD]">
      <div className="container">
        <span className="tw-text-sm poppins tw-font-semibold tw-text-[#001B87]">
          You're Our Priority
        </span>
        <h3 className="tw-text-4xl tw-font-semibold tw-text-[#001B87] poppins">
          We’re here because of you.
        </h3>
        <p className="tw-mt-8 tw-text-xl tw-font-normal poppins tw-text-[#333]">
          Viscio makes back-loading logistics profitable for agro-logistics
          providers and enables farmers/food aggregators to move their produce
          from farm to market affordably & predictably. The African logistics
          scene is riddled with infrastructure gaps, high operating costs, and
          overly fragmented activities.
        </p>

        <p className="tw-mt-6 tw-text-xl tw-font-normal poppins tw-text-[#333]">
          Viscio express platform serves as a reliable collaboration tool for
          various logistics agents across multiple locations leveraging on
          technology tools and a smart operations framework.
        </p>

        <p className="tw-mt-6 tw-text-xl tw-font-normal poppins tw-text-[#333]">
          Our commitment is to facilitate the movement of packages and cargo
          from point of origin to the destination desired by the user. We are
          creating Africa's largest logistics marketplace and collaboration tool
          across multiple-modal channels.
        </p>

        <p className="tw-mt-6 tw-text-xl tw-font-normal poppins tw-text-[#333]">
          In ensuring that we keep to our promise of safely moving your goods
          from one point to another, we go the extra mile in providing
          end-to-end logistics efficiency by practically closing the
          infrastructure gaps that limit the deployment of our technology tools.
          Our Key Stakeholders: Logistics Agents, Infrastructure partners, and
          various courier organizations.
        </p>
        <div className="tw-mt-8 tw-w-full tw-grid tw-grid-cols-2 tw-gap-8">
          <img src={Pic1} alt="" className="tw-w-full tw-h-full" />
          <div>
            <img src={Pic2} alt="" className="tw-w-full" />
            <img src={Pic3} alt="" className="tw-w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

const TeamSection = () => {
  const Teams = [
    { img: Adeoye, name: "Adeoye Oluwatosin", role: "Founder & CEO" },
    { img: Enene, name: "Enene Edim", role: "Marketing Manager" },
    { img: Akani, name: "Akaninyene Inyang", role: "Operations Manager" },
    { img: John, name: "John Kalu", role: "Product Designer" },
  ];
  return (
    <section className="tw-w-full  tw-bg-white tw-py-24">
      <div className="container">
        <h4 className="tw-text-4xl tw-text-[#101828] tw-text-center inter tw-font-semibold">
          Meet our team
        </h4>
        <p className="tw-text-xl tw-font-normal inter tw-mt-6 tw-max-w-xl tw-mx-auto tw-text-center tw-text-[#667085]">
          Meet the Dedicated Professionals Powering Africa’s Largest
          Agro-Logistics Marketplace
        </p>
        <div className="tw-mt-12 tw-flex tw-flex-col lg:tw-flex-row tw-space-y-6 lg:tw-space-y-0 lg:tw-justify-between">
          {Teams?.map((t) => (
            <div className="tw-w-72 tw-mx-auto lg:tw-mx-0">
              <div className="tw-flex tw-justify-center">
                <img src={t?.img} alt="" className="tw-mx-auto" />
              </div>
              <h6 className="tw-text-lg tw-mt-6 tw-text-center tw-font-medium tw-text-[#101828] inter">
                {t?.name}
              </h6>
              <h6 className="tw-text-base tw-text-center tw-font-normal inter tw-text-[#006CEA]">
                {t?.role}
              </h6>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
const BecomeAgentSection = () => {
  const benefits = [
      "Viscio provides improved market access to a large pool of prospective customers. For fleet operators, Viscio increases sales prospects and overall operational efficiency with access to customized tools.",
      "Viscio will provide improved visibility and tracking of your mobility asset leveraging on our telematics framework To begin, download our Viscio Express Driver App and follow the steps below:",
    ],
    apps = [
      "Registration and Otp Verification (with a fully completed profile)",
      "Prospective Logistics Agents are required to select mobility asset type (motorbike, vehicle or truck), year of production, plate number and uploads of documents (driver license, vehicle registration document and complete )",
      "Our Quality Assessment Agent will verify/screen your application after which you become verified on the Viscio express platform; which means you can start receiving requests.",
      "For complete information about required documents, click here for checklist.",
      "For more information kindly send an email to: support@viscio.com.ng",
    ];
  return (
    <section className="tw-w-full tw-border">
      <div className="container">
        <h2 className="lg:tw-text-5xl tw-text-3xl tw-font-semibold tw-text-[#001B87] lg:tw-text-center">
          Become a logistics agent
        </h2>
        <div className="tw-mt-10 tw-text-[#111114]">
          <p className="tw-text-xl tw-font-light poppins">
            You can truly become your own boss as a Viscio express logistics
            agent whether as an owner of a motorcycle, vehicle or a fleet of
            mobility assets. Viscio is a collaborative tool that depends on
            logistics agents and logistics infrastructure partners. As our
            logistics agent you can earn by fulfilling logistics requests in any
            of the cities we operate.
          </p>
          <h5 className="tw-mt-5 tw-font-normal tw-text-2xl">
            Benefits our Logistics Agent Enjoy
          </h5>
          <ul className="tw-list-disc tw-text-xl tw-font-light poppins">
            {benefits?.map((b) => (
              <li>{b}</li>
            ))}
          </ul>
          <ul className="tw-list-disc tw-mt-4 tw-text-xl tw-font-light poppins">
            {apps?.map((b) => (
              <li>{b}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
export default About;
